<template>
  <el-container class="container">
    <el-header class="header">
      <!-- <span class="left el-icon-arrow-left" @click="returnOn"></span> -->
      结算完成
    </el-header>
    <el-main>
      <div class="content">
        <div class="top">
          <div class="icon el-icon-circle-check"></div>
          <div class="text">结算完成</div>
          <div class="text1">通过账单可查看详情</div>
        </div>
        <div class="body">
          <div class="cell-box">
            <div class="b-title iconfont icon-meiyuan">费用明细</div>
            <div class="b-cell">
              <div class="cell-item">
                <div class="left">费用合计</div>
                <div class="right">
                  <b>¥{{ info.total_price }}</b>
                </div>
              </div>
              <div class="cell-item">
                <div class="left">会员折扣</div>
                <div class="right">
                  <b>¥{{ info.usergrade }}</b>
                </div>
              </div>
            </div>
          </div>
          <div class="cell-box">
            <div class="c-title">优惠金额</div>
            <div class="b-cell">
              <div class="cell-item" v-if="info.couponname">
                <div class="left">{{ info.couponname }}</div>
                <div class="right">
                  <b>-¥{{ info.couponmon }}</b>
                </div>
              </div>
              <div class="cell-item">
                <div class="left">{{ info.score }}积分抵现</div>
                <div class="right">
                  <b>-¥{{ info.score_price }}</b>
                </div>
              </div>
              <div class="cell-item">
                <div class="left">卡券抵现</div>
                <div class="right">
                  <b>-¥{{ info.cardmoney }}</b>
                </div>
              </div>
              <div class="cell-item">
                <div class="left">整单抹零</div>
                <div class="right">
                  <b>-¥{{ info.rub_price }}</b>
                </div>
              </div>
            </div>
          </div>
          <div class="cell-box">
            <div class="c-title">实付金额</div>
            <div class="b-cell">
              <div class="cell-item">
                <div class="left">支付金额</div>
                <div class="right">
                  <b>¥{{ info.real_price }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btm">
          <!-- <div class="btn" @click="jumpServiceBills">查看账单详情</div> -->
          <div class="btn" @click="jumpCashReg">{{name=="fuwu"?'返回预约':'返回继续收银'}}</div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      info: {},
      name: "",
    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.info = JSON.parse(this.$route.query.info);
    if (this.$route.query.name) {
      this.name = this.$route.query.name;
    }
    console.log(this.info,this.name);
  },
  methods: {
    // 返回上级
    returnOn() {
      this.$router.go(-1);
    },
    jumpCashReg() {
      if (this.name == "fuwu") {
        this.$router.push({
           path: '/reservation'
        });
      } else {
        this.$router.push({
          name: "CashReg",
        });
      }
    },
    jumpServiceBills() {
      this.$router.push({
        name: "ServiceBills",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  height: calc(100vh);
  background-color: #f9f9f9;
  color: #666;

  // 头部
  .el-header {
    position: relative;
    z-index: 1;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;

    .left {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      color: #46a6ff;
      font-size: 24px;
    }
  }

  .content {
    padding: 20px;

    .top {
      text-align: center;

      .icon {
        font-size: 72px;
        color: #19be6b;
        margin: 30px 0 20px;
      }

      .text {
        font-size: 16px;
        font-weight: bold;
      }

      .text1 {
        font-size: 12px;
        color: #999;
        margin: 10px 0 20px;
      }
    }

    .body {
      background-color: #fff;
      border: 1px solid #f5f5f5;
      padding: 15px;

      .cell-box {
        .b-title {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 15px;

          &::before {
            color: #fec03d;
            font-weight: normal;
          }
        }

        .c-title {
          font-weight: bold;
          line-height: 40px;
        }

        .cell-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 30px;
        }
      }
    }

    .btm {
      display: flex;
      justify-content: space-around;
      margin: 20px 0;

      .btn {
        width: 40%;
        border: 1px solid #409eff;
        color: #46a6ff;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;

        &:first-child {
          background-color: #409eff;
          color: #fff;
        }
      }
    }
  }
}

.main.el-main {
  padding: 0 5px !important;
}

.main.el-main.right-main {
  padding: 0 !important;
}
</style>
